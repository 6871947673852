require("./bootstrap");
require("owl.carousel");
require("jquery");

$(document).ready(function() {
    $(".nav-item .no-collapse").click(function() {
        $(".drop .collapse").collapse("hide");
    });

    $(".slide").owlCarousel({
        loop: true,
        margin: 10,
        dots: false,
        nav: true,
        navText: [
            "<span aria-label='Previous' style='font-size: 60px;'>‹</span>",
            "<span aria-label='Next' style='font-size: 60px;'>›</span>"
        ],
        lazyLoad: true,
        items: 1,
        autoplay: true
    });

    $(".product").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            "<i class='fas fa-arrow-left color-cyan'></i>",
            "<i class='fas fa-arrow-right color-cyan'></i>"
        ],
        lazyLoad: true,
        items: 1,
        autoplay: true
    });

    $("#blogs").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            "<i class='fas fa-arrow-left color-cyan'></i>",
            "<i class='fas fa-arrow-right color-cyan'></i>"
        ],
        lazyLoad: true,
        items: 1,
        autoplay: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,
                nav: true
            },
            700: {
                items: 2,
                nav: true
            }
        }
    });

    $("#blogs-internal").owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [
            "<i class='fas fa-arrow-left color-cyan'></i>",
            "<i class='fas fa-arrow-right color-cyan'></i>"
        ],
        lazyLoad: true,
        items: 1,
        autoplay: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,
                nav: true
            },
            700: {
                items: 2,
                nav: true
            },
            1000: {
                items: 3,
                nav: true
            }
        }
    });

    $(".gallery").owlCarousel({
        loop: false,
        margin: 10,
        nav: true,
        navText: [
            "<img src='/images/icons/arrow-prev.svg'>",
            "<img src='/images/icons/arrow-next.svg'>"
        ],
        lazyLoad: true,
        items: 1,
        autoplay: true,
        dots: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 1,
                nav: true
            },
            700: {
                items: 2,
                nav: true
            },
            1000: {
                items: 3,
                nav: true
            }
        }
    });

    $("#products").owlCarousel({
        loop: false,
        margin: 10,
        nav: false,
        lazyLoad: true,
        autoplay: true,
        dots: false,
        responsiveClass: true,
        items: 3
    });

    $(".nav-products").owlCarousel({
        loop: false,
        nav: false,
        items: 2,
        autoplay: false,
        dots: false
    });

    $(".nav-product").owlCarousel({
        loop: false,
        nav: true,
        items: 1,
        navText: [
            "<i class='fas fa-arrow-left color-cyan'></i>",
            "<i class='fas fa-arrow-right color-cyan'></i>"
        ],
        autoplay: false,
        dots: false
    });

    $("#category").on("change", function() {
        let select = $(this)
            .find("option:selected")
            .val();
        if (select) {
            $(".item-blog").hide();
            $("." + select).show();
        } else {
            $(".item-blog").show();
        }
    });

    $(".sidebar-btn").on("click", function() {
        $("#sidebar").toggleClass("visible");
        $(".cheeseburger-menu-overlay").toggleClass("d-block");
    });
});

//Lazy Load
function logElementEvent(eventName, element) {
    console.log(Date.now(), eventName, element.getAttribute("data-src"));
}

var callback_enter = function(element) {
    logElementEvent("🔑 ENTERED", element);
};
var callback_exit = function(element) {
    logElementEvent("🚪 EXITED", element);
};
var callback_loading = function(element) {
    logElementEvent("⌚ LOADING", element);
};
var callback_loaded = function(element) {
    logElementEvent("👍 LOADED", element);
};
var callback_error = function(element) {
    logElementEvent("💀 ERROR", element);
    element.src = "https://via.placeholder.com/440x560/?text=Error+Placeholder";
};
var callback_finish = function() {
    logElementEvent("✔️ FINISHED", document.documentElement);
};
var callback_cancel = function(element) {
    logElementEvent("🔥 CANCEL", element);
};

window.lazyLoadOptions = {
    threshold: 0,
    // Assign the callbacks defined above
    callback_enter: callback_enter,
    callback_exit: callback_exit,
    callback_cancel: callback_cancel,
    callback_loading: callback_loading,
    callback_loaded: callback_loaded,
    callback_error: callback_error,
    callback_finish: callback_finish
};
window.addEventListener(
    "LazyLoad::Initialized",
    function(e) {
        console.log(e.detail.instance);
    },
    false
);
